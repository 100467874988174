.App {
  display: flex;
  justify-content: center;

}


.title {
  padding: 10px;
  font-size: 1.5em;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #55959f;
}

.title, .lower-title {
  font-size: 1.3em; /* Adjusted for better scaling */
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #55959f;
}

.lower-title {
  color: #35646b;
  background-color: white;
  padding: 10px 0;
}

.outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: #55959f 1px solid;
  max-width: 800px; /* or any appropriate fixed width */
  width: 95%; /* This makes it responsive up to the max-width */
  margin: auto;
  z-index: 1001;
  border-radius:  0px 0px 15px 15px;
}

.column-row {
  display: flex;
  align-items: center;
}

.category-top {
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #55959f;
  
  font-size: 1em;
}


.column-row {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #55959f;
  padding: 0px 10px;
  font-size: 1em;
}

.column-row .category-bottom {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #55959f;
  padding: 0px;
  font-size: 1em;
}

.category-bottom {
  width: 40%;
}



input, .name-title, .current-title, .new-title, .difference-title, .current, .new, .difference{
  box-sizing: border-box;
  font-size: 1em;
}

span {
  font-size: 1.2em;
  color: #55959f;
  font-size: 1em;
  margin-left: .8em;
}

.input {
  font-size: 1em;
  color: #55959f;
  width: 80%;
  
 
  border: #55959f 1px solid;
  background-color: #ffffff;
  border-radius: 5px;
  }

  .currentInput, .currentInput:focus{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5px;
    padding: 4px;
    border: #55959f 1px solid;
    background-color: #ffffff;
    border-radius: 5px;
    color: #55959f;
    font-weight: bold;
  }

  .currentInput:focus{
    outline: none;
  }

.difference {
  font-size: 1em;
  color: #55959f;
  font-weight: bold;
  width: 20%;
  display: flex;
  justify-content: center;
  font-size: .9em;
}
.column-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #9aced6;
  }
  .column-header-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    }

  .name-title, .current-title, .new-title, .difference-title {
    font-weight: bold;
  }

  .name-title {
    width: 40%;
   
  }

  .current-title, .current {
    width: 20%;
    display: flex;
    justify-content: center;
   
   
  }

  .current, .new {
    color: #55959f;
  }

  .new-title, .new {
    width: 20%;
    display: flex;
    justify-content: center;
   
   }

  .difference-title, .difference {
    width: 20%;
    display: flex;
    justify-content: center;
    
  }

  .notes {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
  } 
  /* div {
    border: #000000 1px solid;  
  } */


input[type="range"]::-webkit-slider-thumb {
  height: 25px; /* Test change */
  width: 25px; /* Test change */
}

/* App.css */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #55959f;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  background: #55959f;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.submit-button {
  width: 45%;
  padding: 10px 20px;
  font-size: 16px;
  margin: 20px;
  cursor: pointer;
  
  /* Gradient background */
  background-image: linear-gradient(to bottom, #55949f, #417880);
  
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  transition: transform 0.2s ease, background-image 0.2s ease; /* Smooth transition for hover effects */
}

.submit-button:hover {
  /* Scale button on hover to give a press effect */
  transform: scale(1.05);

  /* Change gradient for hover effect */
  background-image: linear-gradient(to bottom, #6ba7ab, #55949f);
}

.submit-button:active {
  /* Slightly darkens the button as if it's being pressed */
  background-image: linear-gradient(to bottom, #3c6f73, #417880);
  transform: scale(0.98); /* Scales down to simulate a press */
}

.notes-text {
  border: #55959f solid 1px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.notes-text:focus {
  border: 1px solid #55959f; /* Specify the width, style, and color */
}

.nav-button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 5px;
  margin-top: 10px;
  cursor: pointer;
  /* Updated gradient using #35646b */
  background-image: linear-gradient(to bottom, #35646b, #2a5260);
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  transition: transform 0.2s ease, background-image 0.2s ease, box-shadow 0.2s ease;
}

.nav-button:hover {
  /* Lighter gradient on hover */
  background-image: linear-gradient(to bottom, #3b707a, #315e69);
  transform: scale(1.05); /* Slightly enlarge the button */
}

.nav-button:active {
  /* Darker gradient on click */
  background-image: linear-gradient(to bottom, #2d4d57, #234447);
  transform: scale(0.95); /* Slightly shrink the button */
}

.nav-button:focus, .nav-button.active-state {
  /* Add a box shadow to simulate a focus or "selected" state */
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #35646b;
  text-decoration: underline;
}




.currentInput:focus {
  border: 2px solid #55949f; /* Style for required fields that are invalid after the user focuses on them */
}



.notes-text:focus {
  border: 2px solid #55959f; /* Change #55959f to your preferred border color */
  outline: none; /* Optional: Removes the default focus highlight */
}
